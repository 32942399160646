#menu {
    padding: 0;

}

.primaryMenu {
  &-list {
    margin-top: 0;
    margin-left: 0;
  }

  &-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    text-transform: uppercase;
    color:$secondary !important;
    font-size: 1.1rem;
    margin: 0 20px;
  }
    &-item .megamenu-content-child{
        margin: 10px 5px;
    }

}    
    
.primaryMenu-link{display: block;padding: 0}


.dropdown-menu{
    border: none; 
    border-radius:0px;
    min-width: inherit;
    z-index: 1000;
    @media (min-width: 992px) {  
        background:#fff;
        box-shadow: $bs2;
    }
    .dropdown-item{  
        padding:0;
    
        &:hover, &:focus{
            color: #16181b; 
            text-decoration: none; 
            background-color: transparent; 
        }
        
        a{  border-bottom: 1px solid transparent;
            
            &:hover, &:focus{ border-bottom: 1px solid #000;}
        }    
    }    
    

}

@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}