footer {
  z-index: 1000;
  position: relative;
  background: #fff;
  padding: 20px 0 0;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1)
}

#logo-foo img{max-width: 280px}


#footer-bottom{
    background: #474747;
    color:#fff;
    padding-bottom: 10px;
    text-align: center;

    #footer-admin a {
      color: #fff;
      text-decoration: none;
    }



    #list-footer {
      list-style: none;
        margin: 10px auto 0;

      li {
        display: inline-block;
        margin: 0 10px 0 0;
      }
        
       li.company{text-transform:uppercase}  

      a {color:#fff}
    }

    /* pictos en png */

    #footer-realisation a {
      img {
        border: 0;
      }

      &:hover {
        background: none;
      }
    }
}