html {
    font-size: $html-font-size;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    @media (max-width: 991px) {
        font-size: rem($html-font-size * .8);
    }
}

body {
    margin: 0;
    padding: 0;
    text-align: left;
    line-height: 1.1;
    background: $body-bg;
    font-family: $ffText;
    color: $colorText;
    font-size: rem($fzText);
    --swiper-theme-color: #{$swiperThemeColor};
}

.hidden {
    display: none;
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: $widthContainer;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: rem(20px) 0;
    padding: 0;
    font-weight: 700;
    font-family: $ffTitle;
    color: $colorTitle;
}

$listStyles: ("h1": $fzH1, "h2": $fzH2, "h3": $fzH3, "h4": $fzH4, "h5": $fzH5, "h6": $fzH6);

@each $element, $size in $listStyles {
    #{$element} {
        font-size: rem($size);
    }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="week"],
input[type="month"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
optgroup,
select,
textarea,
.form-control {
  border: 1px solid gainsboro;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #fff;
}

.form-control:focus {
    border-color: rgba($primary, .25);
    box-shadow: 0 0 0 0.2rem rgba($primary, .25);
}

ul, ol {
    margin: 10px;
    padding: 0;
    list-style-position: inside;
}

.dib{display: inline-block}

a {
    transition: all 0.2s linear;
    color: $colorLink;

    &:hover {
        text-decoration: none;
        color: $colorLink;
    }

    &:focus {
        outline: 0;
    }

    img {
        border: 0;
    }

    // &[href$=".pdf"] {
    //     padding: 10px 0 10px 30px;
    //     background: url(/img/pdf.png) no-repeat 0 50% transparent;
    // }
}


blockquote {
    margin: 30px 0;
    padding: 5px 15px;
    border-left: 4px solid #eee;
    font-style: italic;
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

.overflow {
    overflow: hidden;
}

.scrollToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000000;
    display: none;

    &-icon {
        fill: $primary;
        width: 35px;
        height: 35px;
    }
}



/* #CONTENT
================================================== */

#content {
    padding-bottom: 20px;
}

/* ------ mentions légales -----
#mentions-bloc { font-size: 0.8em; color: #000; width: 440px; position: fixed; left: 50%; margin-left: -220px; top: 50px; background: #fff; border: 10px solid #eee; padding: 10px; border-radius: 6px; display: none; box-shadow: 0 0 2px #000; z-index: 100000; }
@media (max-width: 767px) {
#mentions-bloc { width: 400px; font-size: 0.7em; margin-left: -200px}
}
#close_mentions { width: 20px; height: 20px; cursor: pointer; position: absolute; top: -5px; right: -5px; background: url(../img/close_mentions.png) no-repeat transparent; text-indent: -9999px; }

#mentions-bloc {
max-width: 550px;
border-radius: 4px;
color: #000;
font-size: 0.8em;
transform: translateY(-50px);
transition: all .33s;
}
*/

.fancybox-slide--current #hidden-content-b {
    transform: translateY(0);
}

/* ----- NOTIFICATIONS -----*/

.result_error {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #e57c7c;
}

.result_success {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #78ca71;
}

#flashMessage {
    background: #fff;
    color: #337ab7;
    line-height: 40px;
    text-align: center;
}

// Connexion
#tableau_login { overflow:hidden; }

#compte { margin: 20px auto}

#UserSiteLoginForm div, #UserAddUserForm div { margin: 0 0 10px; }

.error-message { color: #a94442}

// Contact
.accept {
    display: inline;
}

.error {
    .form-control {
        border-color: $danger;
    }
    .control-label {
        color: $danger;
    }
}

.mandatory {
    height: 0.1px;
    width: 0;
    border: 0 !important;
    padding: 0 !important;
}

.mentionRGPD {
    font-size: rem(14px);
}

#map {
    background: $gray-200;
    width: 100%;
    height: 500px;
    color: #000;
}

/* ----- DEMO ASSETS ----- */

.bs-component {
    .modal {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        width: 90%;
    }
}

.modal-title {
    margin-top: 0;
}

/* ----- Message de consentement RGPD ----- */

.mentionRGPD {
    font-size: 0.8em;
}

// pagination
.pagination {
    justify-content: center;
}

.page {
    &-item {
        &.active {
            background-color: $primary;

            .page-link {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }

    &-link {
        &, &:hover {
            color: $primary;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

// Surclasses les boutons Bootstrap
// $btns:
// "primary" $primary,
// "secondary" $secondary,
// "success" $green,
// "danger" $red,
// "warning" $yellow,
// "info" $cyan,
// "light" $light,
// "dark" $dark;
//
// @each $modifier, $colorBtn in $btns {
//     .btn-#{$modifier} {
//         @include bouton($hover: false, $color: $colorBtn);
//
//         &:not(:disabled):not(.disabled) {
//             &:hover, &:focus, &:active {
//                 @include hoverBouton($color: $colorBtn);
//             }
//         }
//     }
//
//     .btn-outline-#{$modifier} {
//         @include boutonOutline($hover: false, $color: $colorBtn);
//
//         &:not(:disabled):not(.disabled) {
//             &:hover, &:focus, &:active {
//                 @include hoverBoutonOutline($color: $colorBtn);
//             }
//         }
//     }
// }


/* ----- BTN ----- */

.btn{border-radius: 0;font-size: 1.2rem ;padding: 5px 20px}

/* 
**Default
*/
.btn-default {background: #474747;border: 1px solid #474747;color: #fff;font-weight: 700;}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .show > .btn-default.dropdown-toggle, btn-default:not(:disabled):not(.disabled):active:focus {background: #fff; border:1px solid #474747;color:#474747;    box-shadow: none;}


/* 
**Primary
*/
.btn-primary {background: transparent;border: 1px solid #474747;color: #474747;font-weight: 700;}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active:focus {background: #474747; border:1px solid #474747;color:#fff;    box-shadow: none;}

/* 
**Succes
*/
.btn-success {background: #5cb85c;border: 0;}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active{}

/* 
**Info
*/
.btn-info {background: #2aabd2;border: 0;}

.btn-info:hover, .btn-info:focus, .btn-info.focus, .btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info{background: red;border: 0;}




header {
    position: relative;
    z-index: 100;
    text-align: center; 
    box-shadow: $bs0;
}

#logo{width: 300px;margin: auto}

#navbarSupportedContent{ justify-content: center;}     



ol.breadcrumb {
    margin: 10px 0;
}

.breadcrumb{
    background-color: #fff;
    border-radius: 0;
    border-bottom:none;
}




@media (max-width: 992px) {
    #logo{margin: 0;}
    #menu{text-align: right;
        .navbar{justify-content: end;
            button{margin-top: -60px;}
        }
        
        .primaryMenu-link{text-align: right;padding: 10px 0}
        .primaryMenu-item .megamenu-content-child{margin: 0;padding-right: 25px}
    }  

}    
  
@media (max-width: 767px) {
    #logo{width:70%}
    #mea_rea { margin-top: 50px; padding-bottom: 40px;}
    .widget_contact{text-align: center;} 
    .footer-reseaux{margin-top: 30px}
}