.rs {
  $parent: &;
  display: flex;
  flex-wrap: wrap;

  $parent: &;

  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin: 0 -5px;
  }

  &-item {
    display: flex;
    margin: 5px;
  }

  &-link {
    display: inline-flex;

    &:active,
    &:focus,
    &:hover {
      #{$parent}-icon {
        fill: darken($primary, 5%);
      }
    }
  }

  &-icon {
    width: 30px;
    height: 30px;
    transition: transform .5s, fill .5s;
    fill: #fff;
    vertical-align: text-top;
    background: #474747;
    padding: 5px;
  }
}
