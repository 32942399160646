header {
  background: $bgcHeader;
  padding: 20px 0;
}

.topBar {
  display: flex;
  flex-wrap: wrap;
  line-height: 3em;
  justify-content: space-between;
}

#logo {
  img {
    max-width: 100%;
    height: auto;
  }
}