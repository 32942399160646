@charset "UTF-8";
@import '~bootstrap/dist/css/bootstrap.css';
@import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";
@import '~jquery-ui/themes/base/all.css';
@font-face {
  font-family: "Champagne";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.eot");
  /* IE9*/
  src: url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.eot?#iefix") format("embedded-opentype"), url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.woff2") format("woff2"), url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.woff") format("woff"), url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.ttf") format("truetype"), url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.svg#Champagne & Limousines V1") format("svg");
  /* iOS 4.1- */
}
@font-face {
  font-family: "Champagne & Limousines";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.eot");
  src: url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.eot?#iefix") format("embedded-opentype"), url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.woff2") format("woff2"), url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.woff") format("woff"), url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.ttf") format("truetype"), url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.svg#Champagne & Limousines") format("svg");
}
/* cabin-regular - latin */
@font-face {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/cabin/cabin-v18-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/fonts/cabin/cabin-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/cabin/cabin-v18-latin-regular.woff2") format("woff2"), url("/fonts/cabin/cabin-v18-latin-regular.woff") format("woff"), url("/fonts/cabin/cabin-v18-latin-regular.ttf") format("truetype"), url("/fonts/cabin/cabin-v18-latin-regular.svg#Cabin") format("svg");
  /* Legacy iOS */
}
/* cabin-700 - latin */
@font-face {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/cabin/cabin-v18-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/fonts/cabin/cabin-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/cabin/cabin-v18-latin-700.woff2") format("woff2"), url("/fonts/cabin/cabin-v18-latin-700.woff") format("woff"), url("/fonts/cabin/cabin-v18-latin-700.ttf") format("truetype"), url("/fonts/cabin/cabin-v18-latin-700.svg#Cabin") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "open_sans";
  src: url("/fonts/OpenSans/OpenSans-Regular-webfont.eot");
  src: url("/fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular") format("svg"), url("/fonts/OpenSans/OpenSans-Regular-webfont.woff") format("woff"), url("/fonts/OpenSans/OpenSans-Regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "open_sans";
  src: url("/fonts/OpenSans/OpenSans-BoldItalic-webfont.eot");
  src: url("/fonts/OpenSans/OpenSans-BoldItalic-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic") format("svg"), url("/fonts/OpenSans/OpenSans-BoldItalic-webfont.woff") format("woff"), url("/fonts/OpenSans/OpenSans-BoldItalic-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "open_sans";
  src: url("/fonts/OpenSans/OpenSans-Bold-webfont.eot");
  src: url("/fonts/OpenSans/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans/OpenSans-Bold-webfont.svg#open_sansbold") format("svg"), url("/fonts/OpenSans/OpenSans-Bold-webfont.woff") format("woff"), url("/fonts/OpenSans/OpenSans-Bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "open_sans";
  src: url("/fonts/OpenSans/OpenSans-Semibold-webfont.eot");
  src: url("/fonts/OpenSans/OpenSans-Semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans/OpenSans-Semibold-webfont.svg#open_sanssemibold") format("svg"), url("/fonts/OpenSans/OpenSans-Semibold-webfont.woff") format("woff"), url("/fonts/OpenSans/OpenSans-Semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "open_sans";
  src: url("/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.eot");
  src: url("/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.svg#open_sanssemibold_italic") format("svg"), url("/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.woff") format("woff"), url("/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "open_sans";
  src: url("/fonts/OpenSans/OpenSans-Italic-webfont.eot");
  src: url("/fonts/OpenSans/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans/OpenSans-Italic-webfont.svg#open_sansitalic") format("svg"), url("/fonts/OpenSans/OpenSans-Italic-webfont.woff") format("woff"), url("/fonts/OpenSans/OpenSans-Italic-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "open_sans";
  src: url("/fonts/OpenSans/OpenSans-LightItalic-webfont.eot");
  src: url("/fonts/OpenSans/OpenSans-LightItalic-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans/OpenSans-LightItalic-webfont.svg#open_sanslight_italic") format("svg"), url("/fonts/OpenSans/OpenSans-LightItalic-webfont.woff") format("woff"), url("/fonts/OpenSans/OpenSans-LightItalic-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "open_sans";
  src: url("/fonts/OpenSans/OpenSans-Light-webfont.eot");
  src: url("/fonts/OpenSans/OpenSans-Light-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans/OpenSans-Light-webfont.svg#open_sanslight") format("svg"), url("/fonts/OpenSans/OpenSans-Light-webfont.woff") format("woff"), url("/fonts/OpenSans/OpenSans-Light-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon/icomoon.eot?hyfua1");
  src: url("/fonts/icomoon/icomoon.eot?hyfua1#iefix") format("embedded-opentype"), url("/fonts/icomoon/icomoon.ttf?hyfua1") format("truetype"), url("/fonts/icomoon/icomoon.woff?hyfua1") format("woff"), url("/fonts/icomoon/icomoon.svg?hyfua1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-twitter1:before {
  content: "";
}

.icon-accept:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-bed:before {
  content: "";
}

.icon-blueprint:before {
  content: "";
}

.icon-cottage:before {
  content: "";
}

.icon-euro-sign:before {
  content: "";
}

.icon-facebook1:before {
  content: "";
}

.icon-high-quality:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-house:before {
  content: "";
}

.icon-house-plan:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-medaille-1:before {
  content: "";
}

.icon-medaille:before {
  content: "";
}

.icon-picket-fence:before {
  content: "";
}

.icon-placeholder:before {
  content: "";
}

.icon-plan:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-zone:before {
  content: "";
}

.icon-zoom:before {
  content: "";
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
@media (max-width: 991px) {
  html {
    font-size: 0.8rem;
  }
}

body {
  margin: 0;
  padding: 0;
  text-align: left;
  line-height: 1.1;
  background: #ffffff;
  font-family: "cabin", Arial, sans-serif;
  color: #474747;
  font-size: 1rem;
  --swiper-theme-color: #ff0000;
}

.hidden {
  display: none;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1340px;
  }
}
h1, h2, h3, h4, h5, h6 {
  margin: 1.25rem 0;
  padding: 0;
  font-weight: 700;
  font-family: "champagne", Arial, sans-serif;
  color: #736f6f;
}

h1 {
  font-size: 2.8125rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2.1875rem;
}

h4 {
  font-size: 1.875rem;
}

h5 {
  font-size: 1.5625rem;
}

h6 {
  font-size: 1.25rem;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=week],
input[type=month],
input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=tel],
input[type=number],
optgroup,
select,
textarea,
.form-control {
  border: 1px solid gainsboro;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #fff;
}

.form-control:focus {
  border-color: rgba(255, 0, 0, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

ul, ol {
  margin: 10px;
  padding: 0;
  list-style-position: inside;
}

.dib {
  display: inline-block;
}

a {
  transition: all 0.2s linear;
  color: #ff0000;
}
a:hover {
  text-decoration: none;
  color: #ff0000;
}
a:focus {
  outline: 0;
}
a img {
  border: 0;
}

blockquote {
  margin: 30px 0;
  padding: 5px 15px;
  border-left: 4px solid #eee;
  font-style: italic;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

.overflow {
  overflow: hidden;
}

.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000000;
  display: none;
}
.scrollToTop-icon {
  fill: #ff0000;
  width: 35px;
  height: 35px;
}

/* #CONTENT
================================================== */
#content {
  padding-bottom: 20px;
}

/* ------ mentions légales -----
#mentions-bloc { font-size: 0.8em; color: #000; width: 440px; position: fixed; left: 50%; margin-left: -220px; top: 50px; background: #fff; border: 10px solid #eee; padding: 10px; border-radius: 6px; display: none; box-shadow: 0 0 2px #000; z-index: 100000; }
@media (max-width: 767px) {
#mentions-bloc { width: 400px; font-size: 0.7em; margin-left: -200px}
}
#close_mentions { width: 20px; height: 20px; cursor: pointer; position: absolute; top: -5px; right: -5px; background: url(../img/close_mentions.png) no-repeat transparent; text-indent: -9999px; }

#mentions-bloc {
max-width: 550px;
border-radius: 4px;
color: #000;
font-size: 0.8em;
transform: translateY(-50px);
transition: all .33s;
}
*/
.fancybox-slide--current #hidden-content-b {
  transform: translateY(0);
}

/* ----- NOTIFICATIONS -----*/
.result_error {
  color: #fff;
  line-height: 40px;
  text-align: center;
  background: #e57c7c;
}

.result_success {
  color: #fff;
  line-height: 40px;
  text-align: center;
  background: #78ca71;
}

#flashMessage {
  background: #fff;
  color: #337ab7;
  line-height: 40px;
  text-align: center;
}

#tableau_login {
  overflow: hidden;
}

#compte {
  margin: 20px auto;
}

#UserSiteLoginForm div, #UserAddUserForm div {
  margin: 0 0 10px;
}

.error-message {
  color: #a94442;
}

.accept {
  display: inline;
}

.error .form-control {
  border-color: #e3342f;
}
.error .control-label {
  color: #e3342f;
}

.mandatory {
  height: 0.1px;
  width: 0;
  border: 0 !important;
  padding: 0 !important;
}

.mentionRGPD {
  font-size: 0.875rem;
}

#map {
  background: #e9ecef;
  width: 100%;
  height: 500px;
  color: #000;
}

/* ----- DEMO ASSETS ----- */
.bs-component .modal {
  position: relative;
  top: auto;
  right: auto;
  left: auto;
  bottom: auto;
  z-index: 1;
  display: block;
}
.bs-component .modal-dialog {
  width: 90%;
}

.modal-title {
  margin-top: 0;
}

/* ----- Message de consentement RGPD ----- */
.mentionRGPD {
  font-size: 0.8em;
}

.pagination {
  justify-content: center;
}

.page-item.active {
  background-color: #ff0000;
}
.page-item.active .page-link {
  background-color: #ff0000;
  border-color: #ff0000;
}
.page-link, .page-link:hover {
  color: #ff0000;
}
.page-link:focus {
  box-shadow: none;
}

/* ----- BTN ----- */
.btn {
  border-radius: 0;
  font-size: 1.2rem;
  padding: 5px 20px;
}

/* 
**Default
*/
.btn-default {
  background: #474747;
  border: 1px solid #474747;
  color: #fff;
  font-weight: 700;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .show > .btn-default.dropdown-toggle, btn-default:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  border: 1px solid #474747;
  color: #474747;
  box-shadow: none;
}

/* 
**Primary
*/
.btn-primary {
  background: transparent;
  border: 1px solid #474747;
  color: #474747;
  font-weight: 700;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #474747;
  border: 1px solid #474747;
  color: #fff;
  box-shadow: none;
}

/* 
**Succes
*/
.btn-success {
  background: #5cb85c;
  border: 0;
}

/* 
**Info
*/
.btn-info {
  background: #2aabd2;
  border: 0;
}

.btn-info:hover, .btn-info:focus, .btn-info.focus, .btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
  background: red;
  border: 0;
}

header {
  position: relative;
  z-index: 100;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#logo {
  width: 300px;
  margin: auto;
}

#navbarSupportedContent {
  justify-content: center;
}

ol.breadcrumb {
  margin: 10px 0;
}

.breadcrumb {
  background-color: #fff;
  border-radius: 0;
  border-bottom: none;
}

@media (max-width: 992px) {
  #logo {
    margin: 0;
  }

  #menu {
    text-align: right;
  }
  #menu .navbar {
    justify-content: end;
  }
  #menu .navbar button {
    margin-top: -60px;
  }
  #menu .primaryMenu-link {
    text-align: right;
    padding: 10px 0;
  }
  #menu .primaryMenu-item .megamenu-content-child {
    margin: 0;
    padding-right: 25px;
  }
}
@media (max-width: 767px) {
  #logo {
    width: 70%;
  }

  #mea_rea {
    margin-top: 50px;
    padding-bottom: 40px;
  }

  .widget_contact {
    text-align: center;
  }

  .footer-reseaux {
    margin-top: 30px;
  }
}
.lang {
  margin-left: -15px;
  margin-right: -15px;
}
.lang-item {
  padding-left: 15px;
  padding-right: 15px;
}
.lang-item--active {
  font-weight: 700;
}

div#tarteaucitronAlertBig:focus {
  outline: 0;
}

.tarteaucitron-modal-open {
  overflow: hidden;
  height: 100%;
}

#tarteaucitronContentWrapper {
  display: unset;
}

/** 14042021 **/
span.tarteaucitronReadmoreSeparator {
  display: inline !important;
}

/******/
/** 09052021 **/
.tarteaucitronName .tacCurrentStatus, .tarteaucitronName .tarteaucitronReadmoreSeparator {
  color: #333 !important;
  font-size: 12px !important;
  text-transform: capitalize;
}

/**************/
/** 27032021 **/
button.tarteaucitron-toggle-group {
  display: block;
}

span.tarteaucitronH3 {
  font-weight: 700 !important;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-weight: 500 !important;
  font-size: 14px;
  margin-top: 7px;
}

.tarteaucitronLine {
  border-left: 0px solid transparent !important;
}

/*****/
/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {
  html body #tarteaucitronRoot #tarteaucitron ul#tarteaucitronServices_mandatory .tarteaucitronDeny {
    display: none !important;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button,
html body #tarteaucitronRoot #tarteaucitron .tarteaucitronAsk,
html body #tarteaucitronRoot #tarteaucitron .tarteaucitronName {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
    margin-bottom: 8px !important;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
    padding: 16px !important;
  }

  html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
    display: none !important;
  }

  #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block;
  }

  li.tarteaucitronLine .tarteaucitronName span {
    width: 80% !important;
    display: inline-block;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
    width: 10% !important;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    padding: 10px 0;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
    content: "▾";
    font-weight: 700;
    font-size: 14px;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
    content: "▴";
  }
}
@media screen and (min-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
    content: "▾";
    font-weight: 700;
    font-size: 14px;
    margin-left: 15px;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
    content: "▴";
    margin-left: 15px;
  }
}
/****/
/***
* Reset CSS
*/
#tarteaucitronRoot div, #tarteaucitronRoot span, #tarteaucitronRoot applet, #tarteaucitronRoot object, #tarteaucitronRoot iframe, #tarteaucitronRoot h1, #tarteaucitronRoot h2, #tarteaucitronRoot h3, #tarteaucitronRoot h4, #tarteaucitronRoot h5, #tarteaucitronRoot h6, #tarteaucitronRoot p, #tarteaucitronRoot blockquote, #tarteaucitronRoot pre, #tarteaucitronRoot a, #tarteaucitronRoot abbr, #tarteaucitronRoot acronym, #tarteaucitronRoot address, #tarteaucitronRoot big, #tarteaucitronRoot cite, #tarteaucitronRoot code, #tarteaucitronRoot del, #tarteaucitronRoot dfn, #tarteaucitronRoot em, #tarteaucitronRoot img, #tarteaucitronRoot ins, #tarteaucitronRoot kbd, #tarteaucitronRoot q, #tarteaucitronRoot s, #tarteaucitronRoot samp, #tarteaucitronRoot small, #tarteaucitronRoot strike, #tarteaucitronRoot strong, #tarteaucitronRoot sub, #tarteaucitronRoot sup, #tarteaucitronRoot tt, #tarteaucitronRoot var, #tarteaucitronRoot b, #tarteaucitronRoot u, #tarteaucitronRoot i, #tarteaucitronRoot center, #tarteaucitronRoot dl, #tarteaucitronRoot dt, #tarteaucitronRoot dd, #tarteaucitronRoot ol, #tarteaucitronRoot ul, #tarteaucitronRoot li, #tarteaucitronRoot fieldset, #tarteaucitronRoot form, #tarteaucitronRoot label, #tarteaucitronRoot legend, #tarteaucitronRoot table, #tarteaucitronRoot caption, #tarteaucitronRoot tbody, #tarteaucitronRoot tfoot, #tarteaucitronRoot thead, #tarteaucitronRoot tr, #tarteaucitronRoot th, #tarteaucitronRoot td, #tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot canvas, #tarteaucitronRoot details, #tarteaucitronRoot embed, #tarteaucitronRoot figure, #tarteaucitronRoot figcaption, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot output, #tarteaucitronRoot ruby, #tarteaucitronRoot section, #tarteaucitronRoot summary, #tarteaucitronRoot time, #tarteaucitronRoot mark, #tarteaucitronRoot audio, #tarteaucitronRoot video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /*background: initial;*/
  text-align: initial;
  text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot details, #tarteaucitronRoot figcaption, #tarteaucitronRoot figure, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot section {
  display: block;
}

#tarteaucitronRoot ol, #tarteaucitronRoot ul {
  list-style: none;
}

#tarteaucitronRoot blockquote, #tarteaucitronRoot q {
  quotes: none;
}

#tarteaucitronRoot blockquote:before, #tarteaucitronRoot blockquote:after, #tarteaucitronRoot q:before, #tarteaucitronRoot q:after {
  content: "";
  content: none;
}

#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible, #tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

div#tarteaucitronServices {
  margin-top: 21px !important;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey;
}

div#tarteaucitronServices {
  box-shadow: 0 40px 60px #545454;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }

  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}
@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
  }

  #tarteaucitron .tarteaucitronBorder {
    border: 0 !important;
  }

  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }

  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}
/***
 * Common value
 */
#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: #eee;
}

#tarteaucitron .clear {
  clear: both;
}

#tarteaucitron a {
  color: #424242;
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
}

#tarteaucitronRoot button {
  background: transparent;
  border: 0;
}

#tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
  display: block;
}

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/***
 * Root div added just before </body>
 */
#tarteaucitronRoot {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

div#tarteaucitronRoot.tarteaucitronBeforeVisible::before {
  background: #222 !important;
}

#tarteaucitronRoot * {
  box-sizing: initial;
  color: #333;
  font-family: sans-serif !important;
  font-size: 14px;
  line-height: normal;
  vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  margin: 15px 0 28px;
}

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #fff;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}

/***
 * Control panel
 */
#tarteaucitronBack {
  background: #fff;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
}

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  border: 2px solid #333;
  border-top: 0;
  height: auto;
  overflow: auto;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
  background: #333333;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 4px 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px;
}

#tarteaucitron #tarteaucitronDisclaimer {
  color: #555;
  font-size: 12px;
  margin: 15px auto 0;
  width: 80%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  background: rgba(51, 51, 51, 0.07);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  width: 100%;
  box-sizing: border-box;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0px 0px;
  padding: 5px 20px;
  text-align: left;
  width: auto;
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  color: #fff;
  font-weight: 500;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
  text-decoration: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
  font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-size: 14px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  padding: 5px 10px;
  margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  max-width: 270px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647;
}

#tarteaucitron #tarteaucitronInfo a {
  color: #fff;
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  background: rgba(51, 51, 51, 0.1);
  border-left: 5px solid transparent;
  margin: 0;
  overflow: hidden;
  padding: 15px 5px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: #333;
  border: 3px solid #333;
  border-left: 9px solid #333;
  border-top: 5px solid #333;
  margin-bottom: 0;
  margin-top: 21px;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
  color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
  margin-top: 0px !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: left;
  width: 50%;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
  background: gray;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  width: auto;
  border: 0;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background-color: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background-color: #9C1A1A;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
  background-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
  background-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
  color: #333;
  font-size: 12px;
}

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px;
}

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
  width: auto !important;
  margin-left: 0 !important;
  font-size: 14px;
}

span#tarteaucitronDisclaimerAlert {
  padding: 0 10px;
  display: inline-block;
}

#tarteaucitron .tarteaucitronBorder, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border-color: #333 !important;
}

/***
 * Big alert
 */
.tarteaucitronAlertBigTop {
  top: 0;
}

.tarteaucitronAlertBigBottom {
  bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #fff;
  color: #222;
  display: none;
  font-size: 15px !important;
  position: fixed;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
  padding: 40px 0 20px;
  margin: auto;
  box-shadow: 0 0 4px silver;
  border-radius: 0 !important;
  max-width: 250px !important;
  width: calc(100% - 16px) !important;
  min-width: 0 !important;
  bottom: 8px;
  top: auto !important;
  left: 8px;
  right: auto !important;
}
@media (max-width: 767px) {
  #tarteaucitronRoot #tarteaucitronAlertBig {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font: 15px verdana;
  color: #222;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
  margin: 30px 0 !important;
  text-align: left !important;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font-weight: 700;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
  cursor: pointer;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  background: #008300;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px !important;
  line-height: 1.2;
  padding: 5px 11px;
  text-decoration: none;
  margin-left: 7px;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  font-size: 14px !important;
}

#tarteaucitronRoot .tarteaucitronDeny {
  background: transparent;
}

#tarteaucitronAlertBig #tarteaucitronAllDenied2 {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #222;
  margin-left: 0;
  text-align: left;
  font-size: 14px !important;
  padding: 0;
}
#tarteaucitronAlertBig #tarteaucitronAllDenied2 .tarteaucitronCross {
  display: none;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog, #tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  background: #fff;
  color: #222;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 5px 10px;
  border: 1px solid #222;
}

#tarteaucitronPercentage {
  background: #0A0 !important;
  box-shadow: 0 0 2px #fff, 0 1px 2px #555;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronAllow,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}

/***
 * Icon
 */
.tarteaucitronIconBottomRight {
  bottom: 0;
  right: 0;
}

.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0;
}

.tarteaucitronIconTopRight {
  top: 0;
  right: 0;
}

.tarteaucitronIconTopLeft {
  top: 0;
  left: 0;
}

.tarteaucitronIconTopLeft #tarteaucitronManager {
  border-radius: 2px 7px 7px 2px;
}

.tarteaucitronIconTopRight #tarteaucitronManager {
  border-radius: 7px 2px 2px 7px;
}

.tarteaucitronIconBottomLeft #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

.tarteaucitronIconBottomRight #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

#tarteaucitronIcon {
  background: transparent;
  position: fixed;
  display: none;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronIcon #tarteaucitronManager {
  color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
  border: none;
}

#tarteaucitronIcon #tarteaucitronManager img {
  width: 50px;
  height: 50px;
}

#tarteaucitronRoot .tarteaucitronCross::before {
  content: "✗";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronCheck::before {
  content: "✓";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronPlus::before {
  content: "✛";
  display: inline-block;
  color: white;
}

/***
 * Small alert
 */
.tarteaucitronAlertSmallTop, .tarteaucitronAlertSmallBottom {
  bottom: 0;
}

#tarteaucitronAlertSmall {
  background: #333;
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
  background: rgba(255, 255, 255, 0.05);
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
  background-color: gray;
  border-radius: 5px;
  display: block;
  height: 8px;
  margin-bottom: 1px;
  margin-top: 5px;
  overflow: hidden;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  display: block;
  float: left;
  height: 100%;
  width: 0%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
  background-color: #1B870B;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
  background-color: #FBDA26;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  background-color: #9C1A1A;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  padding: 0px 10px;
  vertical-align: top;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
  background: rgba(255, 255, 255, 0.3);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
  display: none;
  max-height: 70%;
  max-width: 500px;
  position: fixed;
  right: 0;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
  background: #fff;
  border: 2px solid #333;
  color: #333;
  font-size: 11px;
  height: auto;
  overflow: auto;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
  color: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
  background: #333;
  margin-top: 21px;
  padding: 13px 0 9px 13px;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
  color: #fff;
  font-size: 16px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  background: rgba(51, 51, 51, 0.1);
  padding: 7px 5px 10px;
  word-wrap: break-word;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
  color: #333;
  text-decoration: none;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft {
  display: inline-block;
  width: 50%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft a strong {
  color: darkred;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListRight {
  color: #333;
  display: inline-block;
  font-size: 11px;
  margin-left: 10%;
  vertical-align: top;
  width: 30%;
}

/***
 * Fallback activate link
 */
.tac_activate {
  background: #333;
  color: #fff;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%;
}

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.tac_activate .tac_float strong {
  color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
  background-color: #1B870B;
  display: inline-block;
}

/***
 * CSS for services
 */
ins.ferank-publicite, ins.adsbygoogle {
  text-decoration: none;
}

div.amazon_product {
  height: 240px;
  width: 120px;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}

.tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronDeny {
  opacity: 0.4;
}

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 80% !important;
  padding: 15px 0 !important;
  margin: -10px auto 40px !important;
  font-size: 1em !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #555;
}

a.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center !important;
  display: block;
  height: 30px;
}

.tarteaucitronMainLine .tarteaucitronH2 {
  font-size: 1.2em !important;
  margin-top: 4px !important;
}

span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important;
}

#tarteaucitronCloseCross {
  position: absolute;
  color: #222;
  font-size: 1.8rem;
  cursor: pointer;
  top: -6px;
  right: 20px;
  text-indent: -99999px;
}
#tarteaucitronCloseCross:after {
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  background: url(/svg/Close.svg) no-repeat transparent;
  background-size: contain;
}

.tarteaucitron-spacer-20 {
  height: 20px;
  display: block;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}

div#tarteaucitronAlertBig::before {
  display: none;
}

header {
  background: transparent;
  padding: 20px 0;
}

.topBar {
  display: flex;
  flex-wrap: wrap;
  line-height: 3em;
  justify-content: space-between;
}

#logo img {
  max-width: 100%;
  height: auto;
}

.headerCart {
  display: flex;
  justify-content: flex-end;
}
.headerCart-menu {
  border-radius: 0;
  left: initial !important;
  right: 0 !important;
}
.headerCart-buttoncontainer {
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
}
.headerCart-item:active a {
  color: #fff;
}
.headerCart-link {
  display: block;
  text-align: right;
}
.headerCart-buttonContainer {
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
}

#menu {
  padding: 0;
}

.primaryMenu-list {
  margin-top: 0;
  margin-left: 0;
}
.primaryMenu-link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  text-transform: uppercase;
  color: #736f6f !important;
  font-size: 1.1rem;
  margin: 0 20px;
}
.primaryMenu-item .megamenu-content-child {
  margin: 10px 5px;
}

.primaryMenu-link {
  display: block;
  padding: 0;
}

.dropdown-menu {
  border: none;
  border-radius: 0px;
  min-width: inherit;
  z-index: 1000;
}
@media (min-width: 992px) {
  .dropdown-menu {
    background: #fff;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  }
}
.dropdown-menu .dropdown-item {
  padding: 0;
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-menu .dropdown-item a {
  border-bottom: 1px solid transparent;
}
.dropdown-menu .dropdown-item a:hover, .dropdown-menu .dropdown-item a:focus {
  border-bottom: 1px solid #000;
}

@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}
.rs {
  display: flex;
  flex-wrap: wrap;
}
.rs-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0 -5px;
}
.rs-item {
  display: flex;
  margin: 5px;
}
.rs-link {
  display: inline-flex;
}
.rs-link:active .rs-icon, .rs-link:focus .rs-icon, .rs-link:hover .rs-icon {
  fill: #e60000;
}
.rs-icon {
  width: 30px;
  height: 30px;
  transition: transform 0.5s, fill 0.5s;
  fill: #fff;
  vertical-align: text-top;
  background: #474747;
  padding: 5px;
}

.vcard .org {
  text-transform: uppercase;
}
.vcard .nickname {
  display: none;
}
.vcard .adr .locality {
  text-transform: uppercase;
}
.vcard .adr .country-name {
  display: none;
}
.vcard abbr {
  border: 0;
}
.vcard .telnumber a {
  color: #fff;
  text-decoration: none;
}
.vcard .faxnumber {
  display: none;
}
.vcard .email_container {
  margin: 20px 0;
}
.vcard .email_name {
  display: none;
}
.vcard a.email {
  text-decoration: underline;
  display: none;
}
.vcard a.email:hover {
  text-decoration: none;
}

footer {
  z-index: 1000;
  position: relative;
  background: #fff;
  padding: 20px 0 0;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
}

#logo-foo img {
  max-width: 280px;
}

#footer-bottom {
  background: #474747;
  color: #fff;
  padding-bottom: 10px;
  text-align: center;
  /* pictos en png */
}
#footer-bottom #footer-admin a {
  color: #fff;
  text-decoration: none;
}
#footer-bottom #list-footer {
  list-style: none;
  margin: 10px auto 0;
}
#footer-bottom #list-footer li {
  display: inline-block;
  margin: 0 10px 0 0;
}
#footer-bottom #list-footer li.company {
  text-transform: uppercase;
}
#footer-bottom #list-footer a {
  color: #fff;
}
#footer-bottom #footer-realisation a img {
  border: 0;
}
#footer-bottom #footer-realisation a:hover {
  background: none;
}

.page {
  padding-bottom: 80px;
}
.page h2 {
  font-size: 2.1rem;
}
.page h3 {
  font-size: 1.8rem;
  color: #474747;
}
.page p {
  font-size: 18px;
}
.page-intro {
  text-align: center;
  position: relative;
  padding: 50px 50px;
  margin: 50px auto 80px;
  z-index: 10;
  background: #fff;
}
.page-intro:before {
  content: url(/img/chev-r-left.png);
  position: absolute;
  left: 10px;
  top: 10px;
}
.page-intro:after {
  content: url(/img/chev-r-right.png);
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.page-intro p {
  font-size: 1.3rem;
}
.page-image {
  position: relative;
  z-index: 10;
  text-align: center;
}
.page-image p img.img-page {
  display: inline-block;
  vertical-align: middle;
  width: 30%;
  margin: 50px 1%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
@media (max-width: 767px) {
  .page-image p img.img-page {
    width: 40%;
    margin: 10px 1%;
  }
}
@media (max-width: 480px) {
  .page-image p img.img-page {
    width: 98%;
    margin: 10px 1%;
  }
}
.page-content {
  position: relative;
  z-index: 1;
}
.page-content-border {
  position: absolute;
  top: -150px;
  left: -3%;
  right: -3%;
  width: 106%;
  height: 125%;
  border: 1px solid #ff0000;
}