@font-face {
  font-family: 'Champagne';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.eot"); /* IE9*/
  src: url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.woff2") format("woff2"), /* chrome、firefox */
  url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.woff") format("woff"), /* chrome、firefox */
  url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("/fonts/champagne/4bc98977ba557db3b2f5bb66bff54e25.svg#Champagne & Limousines V1") format("svg"); /* iOS 4.1- */
}

@font-face {
    font-family: "Champagne & Limousines"; 
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.eot"); 
    src: url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.eot?#iefix") format("embedded-opentype"), url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.woff2") format("woff2"),
    url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.woff") format("woff"),
    url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.ttf") format("truetype"), url("/fonts/champagne/99209559ec222bb4d046e10fcc4353c1.svg#Champagne & Limousines") format("svg"); }


/* cabin-regular - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/cabin/cabin-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/cabin/cabin-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/cabin/cabin-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/cabin/cabin-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/cabin/cabin-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/cabin/cabin-v18-latin-regular.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-700 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/cabin/cabin-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/cabin/cabin-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/cabin/cabin-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/cabin/cabin-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/cabin/cabin-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/cabin/cabin-v18-latin-700.svg#Cabin') format('svg'); /* Legacy iOS */
}


@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Regular-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Bold-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Semibold-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.svg#open_sanssemibold_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Italic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-LightItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Light-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.svg#open_sanslight') format('svg'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon/icomoon.eot?hyfua1');
  src:  url('/fonts/icomoon/icomoon.eot?hyfua1#iefix') format('embedded-opentype'),
    url('/fonts/icomoon/icomoon.ttf?hyfua1') format('truetype'),
    url('/fonts/icomoon/icomoon.woff?hyfua1') format('woff'),
    url('/fonts/icomoon/icomoon.svg?hyfua1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-youtube:before {
  content: "\e902";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-instagram:before {
  content: "\e904";
}
.icon-twitter1:before {
  content: "\e905";
}
.icon-accept:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-bed:before {
  content: "\e908";
}
.icon-blueprint:before {
  content: "\e909";
}
.icon-cottage:before {
  content: "\e90a";
}
.icon-euro-sign:before {
  content: "\e90b";
}
.icon-facebook1:before {
  content: "\e90c";
}
.icon-high-quality:before {
  content: "\e90d";
}
.icon-home:before {
  content: "\e90e";
}
.icon-house:before {
  content: "\e90f";
}
.icon-house-plan:before {
  content: "\e910";
}
.icon-key:before {
  content: "\e911";
}
.icon-medaille-1:before {
  content: "\e912";
}
.icon-medaille:before {
  content: "\e913";
}
.icon-picket-fence:before {
  content: "\e914";
}
.icon-placeholder:before {
  content: "\e915";
}
.icon-plan:before {
  content: "\e916";
}
.icon-plus:before {
  content: "\e917";
}
.icon-zone:before {
  content: "\e918";
}
.icon-zoom:before {
  content: "\e919";
}
